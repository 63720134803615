//@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;1,100;1,300;1,400;1,500;1,700&display=swap');
// #main-menu + div * {
//   font-family: "Montserrat", Helvetica, sans-serif;
// }


// #main-menu + div *:not(.ace_editor *):not(i.fa) {
//   font-family: "Montserrat", Helvetica, sans-serif;
// }

@import '../less/fonts.less';

@primary-color: #26bc6a;
@checkbox-color: #26bc6a;
@menu-highlight-color: #26bc6a;
@background-hover: #f5f5f5;
@font-white-color:#fff;


.ant-menu-item:hover,
.ant-menu-item a:hover,
.ant-menu-item-active,
.ant-menu:not(.ant-menu-inline) .ant-menu-submenu-open,
.ant-menu-submenu-active,.ant-tabs > .ant-tabs-nav .ant-tabs-nav-add:hover , .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu:hover {
  color: @menu-highlight-color !important;
}

.ant-checkbox-checked .ant-checkbox-inner,
.ant-tree-checkbox-checked .ant-tree-checkbox-inner,
.ant-steps-item-process .ant-steps-item-icon,.btn-primary {
  background-color: @checkbox-color !important; 
  border-color:  @checkbox-color !important;
}
.ant-btn-ok{
  color:@font-white-color !important;
  background-color: @primary-color !important;
  border-color: @primary-color !important;
  border-radius: 4px !important;
  text-transform: capitalize;
}
.ant-btn-cancel{
  color:@primary-color !important;
  background-color: @font-white-color!important;
  border-color: @primary-color !important;
  border-radius: 4px !important;
}
.btn-primary{
  text-transform: capitalize;
}
input.form-control, select#database, input[type="file"] {
  font-size: 12px;
}
select option:hover,
select option:focus {
    background-color: #f5f5f5 !important;
}
.ant-checkbox-wrapper:hover .ant-checkbox-inner,
.ant-checkbox:hover .ant-checkbox-inner,
.ant-checkbox-input:focus + .ant-checkbox-inner,
.ant-tree-checkbox-wrapper:hover .ant-tree-checkbox-inner,
.ant-tree-checkbox:hover .ant-tree-checkbox-inner,
.ant-tree-checkbox-input:focus + .ant-tree-checkbox-inner {
  border-color:  @checkbox-color !important;
}

.ant-tree-checkbox-indeterminate .ant-tree-checkbox-inner::after,
.ant-steps-item-finish .ant-steps-item-icon, .ant-switch-checked {
  background-color: @checkbox-color !important; 
}

.dropdown-menu > .active > a, .dropdown-menu > .active > a:hover, .dropdown-menu > .active > a:focus{
  background-color: @background-hover;
  color: #3f5662;
}

.ant-menu-vertical > .ant-menu-item:has(> .is-active),
.ant-menu-item:active,
.ant-menu-submenu-title:active {
  color: inherit !important;
  font-weight: 600;
  background-color: #f7f6f6 !important;
}

/* nvd3 library css override */
.superset-legacy-chart-nvd3 .nvd3 text, .superset-legacy-chart-nvd3 .nvd3 .title, .superset-legacy-chart-nvd3 .nvtooltip , .superset-legacy-chart-nvd3 .nvd3.nv-bullet {
  font-family: @font-family-base ;
  font-size: 12px ;
  font-weight: 500;
}
.table-responsive .fa.fa-arrows-v:before {
  content: "\f0dc" !important;
  color: #e2e2e2;
  font-size: 11px;
}

/* For sorting ascending */
.table-responsive .fa-chevron-up:before {
  content: "\f0dc" !important; 
  font-size: 11px;
  color: transparent; 
  position: relative;
  background: linear-gradient(to bottom, black 50%, #e2e2e2 50%);
  -webkit-background-clip: text; /* Clip background to text */
  background-clip: text;
}

/* For sorting descending */
.table-responsive .fa-chevron-down:before {
  content: "\f0dc" !important; /* Chevron down */
  font-size: 11px;
  color: transparent; /* Hide default color */
  position: relative;
  background: linear-gradient(to top, black 50%, #e2e2e2 50%);
  -webkit-background-clip: text; 
  background-clip: text;
}